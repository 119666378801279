<template>
  <div class="paddingPub container">
    <div class="padTopHead promotionBox">
      <div class="topbanner">
      <swiper ref="mySwiper" :options="swiperOption" :not-next-tick="true">
        <swiper-slide
          v-for="items in datalJson.imgList"
          :key="items.bannerId"
        >
          <img :src="items.bannerImage">
        </swiper-slide>
        <div v-show="datalJson.imgList.length > 1" slot="button-prev" class="swiper-button-prev" />
        <div v-show="datalJson.imgList.length > 1" slot="button-next" class="swiper-button-next" />
      </swiper>
      </div>
      <div class="contentBox">
        <div class="title">{{ $lan === 'tc' ? datalJson.preferentialTitle : datalJson.simplifiedTitle }}</div>
        <div v-html="$lan === 'tc' ? datalJson.preferentialContent : datalJson.simplifiedContent" class="content" />
      </div>
    </div>
  </div>
</template>
<script>
import { getPromotionDetail } from '../utils/request'
export default {
  name: 'Promotion',
  data() {
    return {
      datalJson: {},
      swiperOption: {
        autoplay: 8000,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  mounted() {
    this.getPromotionDetailJson()
  },
  methods: {
    async getPromotionDetailJson() {
      const result = await getPromotionDetail(this.$route.query.preferentialId)
      if (result.head.success) {
        this.datalJson = result.body
      } else {
        this.$message.error(result.head.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.promotionBox {
  position: relative;
  padding-bottom: 34px;
  &::after{
    width: 66%;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 2px dashed #000B8C;
  }
}
.contentBox{
  color: #303030;
  .title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .content {
    ul {
      margin: 28px 0;
      color: #000000;
    }
    .ps{
      margin-top: 40px;
    }
  }
}
@media screen and (max-width: 576px) {
  .promotionBox{
    &::after{
      width: 0;
    }
  }
}
</style>
